import React from "react"
import { Container, Grid } from '@material-ui/core'

export default function privacy({ children }) {
    return (
    <div className={`white-text masthead flex flex-col privacy`} >         
    <div className="blue-gradient" />      
        <Container className="h-full flex items-center justify-center">
            <Grid items>
                <Grid item className="text-center mx-auto">
                    {children}
                </Grid>
            </Grid>
        </Container>
    </div>
    )
}